import * as z from 'zod'
import { DateTime } from 'luxon'

export const validationSchema = z.object({
  name: z.string().min(1).max(50),
  public: z.boolean(),
  imgUrl: z.string().optional(),
  joinButtonText: z.string().min(1).max(20),
  numberOfWinners: z.number().min(1).max(100),
  description: z.string().min(15).max(1000),
  startAt: z.any()
    .refine((date) => DateTime.isDateTime(date), { message: 'Invalid DateTime' })
    .refine((date) => {
      const now = DateTime.now().plus({ minutes: 10 });

      return date >= now;
    }, { message: 'referralBot.newOwnCampaignForm.startDateShouldBeInFutureError' })
  ,
  finishAt: z.any()
    .refine((date) => DateTime.isDateTime(date), { message: 'Invalid DateTime' })
    .refine((date) => {
      const now = DateTime.now();

      return date >= now;
    }, { message: 'referralBot.newOwnCampaignForm.dateMustBeBiggerThatCurrentError' })
    .refine((date) => {
      const oneMonthLater = DateTime.now().plus({ months: 1 });

      return date <= oneMonthLater;
    }, { message: 'referralBot.newOwnCampaignForm.dateMustBeNotLongerThatMonthError' })
})
  .superRefine((input, ctx) => {
    const {startAt, finishAt} = input;

    if (startAt >= finishAt) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'referralBot.newOwnCampaignForm.startAfterFinishError',
        path: ['startAt'],
      });
    }
  })
