import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import {
  ownCampaignConnectBotRoute,
  ownCampaignFundRoute,
  ownCampaignStartRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, startCampaign } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import {
  canStartCampaign,
  getErrorMessage,
  campaignHasNotEnoughBudget, isAiAgent, isBot,
  isChannel,
  isContest,
} from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import CheckIcon from '@mui/icons-material/Check'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import { StartContestCampaignStep } from '@components/ReferralBot/OwnCampaign/Create/Contest/StartContestCampaignStep'
import {
  StartReferralCampaignStep,
} from '@components/ReferralBot/OwnCampaign/Create/Referral/StartReferralCampaignStep'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { StartAiAgentCampaignStep } from '@components/ReferralBot/OwnCampaign/Create/AiAgent/StartAiAgentCampaignStep'

export const Start = () => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const queryClient = useQueryClient()
  const { uuid } = useParams<typeof ownCampaignStartRoute>({
    from: ownCampaignStartRoute.id,
  })
  const {
    data: campaign,
    isPending: isLoadingCampaign,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  const { mutate: startCampaignMutate, isPending: isStartingCampaign } = useMutation({
    mutationFn: startCampaign,
  })

  const startCampaignClick = () => {
    setConfirmDialog(false)

    startCampaignMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.campaignsList.campaignStarted'), { variant: 'success' })
        await queryClient.invalidateQueries({
          queryKey: ['campaign_status', uuid]
        })
        await queryClient.invalidateQueries({
            queryKey: ['campaigns', uuid]
        })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })
        await refetch()
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', { campaignName: campaign?.name }) || '')

    return () => setTitle('')
  }, [campaign])

  const isCanStartCampaign = campaign ? canStartCampaign(campaign) : false;
  const campaignStarted = campaign?.status === CampaignStatus.ACTIVE
  const isBudgetNotEnough = Boolean(campaign && campaignHasNotEnoughBudget(campaign))
  const disableStartCampaignButton =
    isStartingCampaign || isLoadingCampaign || isRefetching || isBudgetNotEnough

  const getPrevRoute = (campaign: CampaignDto) => {
    switch (campaign.type) {
      case CampaignType.CONTEST:
      case CampaignType.AI_AGENT:
        return ownCampaignConnectBotRoute.id;
      default:
        return ownCampaignFundRoute.id;
    }
  }

  return (
    <>
      {campaign && (
        <>
          <CampaignCreateStepper activeStep={'start_campaign'} campaign={campaign} />
          {campaignStarted && (
            <Box
              mt={2}
              mb={2}
            >
              <Alert
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
              >
                {t('referralBot.startCampaign.campaignStarted')}
              </Alert>
            </Box>
          )}
          {isContest(campaign) && !isRefetching && <StartContestCampaignStep campaign={campaign} /> }
          {isAiAgent(campaign) && !isRefetching && <StartAiAgentCampaignStep campaign={campaign} /> }
          {(isChannel(campaign) || isBot(campaign)) && <StartReferralCampaignStep campaign={campaign} /> }
        </>
      )}
      {campaign && isCanStartCampaign && (
        <>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <LoadingButton
              loading={isLoadingCampaign || isRefetching || isStartingCampaign}
              loadingPosition="start"
              startIcon={<RocketLaunchIcon />}
              variant="contained"
              color="success"
              onClick={() => setConfirmDialog(true)}
              disabled={Boolean(disableStartCampaignButton)}
            >
              {t(`referralBot.startCampaign.${isContest(campaign) ? 'scheduleContestForStart' : 'startReferralCampaign'}`)}
            </LoadingButton>
            {isContest(campaign) && (
              <Alert severity="info" sx={{mt: 2}}>{t(`referralBot.startCampaign.startContestTimeExplanation`)}</Alert>
            )}
          </Box>
        </>
      )}
      <CampaignCreateStepperFooter>
        {campaign && (
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            component={LinkButton}
            to={getPrevRoute(campaign)}
            params={{ uuid }}
          >
            {t('referralBot.back')}
          </Button>
        )}
      </CampaignCreateStepperFooter>
      {confirmDialog && (
        <ConfirmDialog
          title={t('referralBot.campaignsList.confirmStart') || undefined}
          handleConfirm={() => startCampaignClick()}
          handleCancel={() => setConfirmDialog(false)}
        />
      )}
    </>
  )
}
