import React from 'react'
import { useAtom } from 'jotai/index'
import { t } from 'i18next'

import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaignsMe } from '@framework/api/referral-bot.api'
import Box from '@mui/material/Box'
import { Button, Divider, ListItem, ListItemSecondaryAction } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import { statusToTranslatableKey } from '@components/ReferralBot/helpers/CampaignStatus'
import { VertMenu } from '@components/ReferralBot/VertMenu'
import MenuItem from '@mui/material/MenuItem'
import { LinkButton } from '@components/LinkButton'
import InfoIcon from '@mui/icons-material/Info'
import List from '@mui/material/List'
import { formatDateTime } from '@components/ReferralBot/helpers/date.helper'
import { meCampaignDetailsBotRoute } from '@components/ReferralBot/Me/router'
import { CampaignTitle } from '@components/ReferralBot/Campaign/CampaignTitle'

export const CampaignsList = () => {
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle('Campaigns list [ME]')

  const { data, isLoading } = useQuery({
    queryKey: ['campaigns_list_me'],
    queryFn: () => fetchCampaignsMe(),
  })

  const campaigns = data || []

  return (
    <>
      {campaigns.length > 0 && (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {campaigns.map((item) => (
                <Box key={item.uuid}>
                  <ListItem>
                    <ListItemText
                        primary={<CampaignTitle campaign={item} />}
                        secondary={
                          <>
                            {t('referralBot.campaignsList.status')}: {t(statusToTranslatableKey(item.status))},{' '}
                            {t('referralBot.joinedCampaigns.numberOfReferrals')}: {item.numberOfReferrals},
                            Agents: {item.numberOfReferrers},
                            Budget: {item.budget},
                            Created: {formatDateTime(item.createdAt)},
                            Started: {formatDateTime(item.startedAt)},
                            Finished: {formatDateTime(item.finishedAt)},
                          </>
                        }
                    />
                    <ListItemSecondaryAction>
                      <VertMenu>
                        <MenuItem>
                          <Button
                              component={LinkButton}
                              startIcon={<InfoIcon />}
                              to={meCampaignDetailsBotRoute.id}
                              params={{ uuid: item.uuid }}
                              variant="text"
                              color="primary"
                          >
                            Details
                          </Button>
                        </MenuItem>
                      </VertMenu>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider
                      variant="middle"
                      component="li"
                  />
                </Box>
            ))}
          </List>
      )}
    </>
  )
}
