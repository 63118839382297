import * as React from 'react'
import { useAtomValue } from 'jotai'

import { Outlet } from '@tanstack/react-router'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useIsFetching } from '@tanstack/react-query'
import LinearProgress from '@mui/material/LinearProgress'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { Paper } from '@mui/material'
import { BottomMenu } from '@components/ReferralBot/Menu/BottomMenu'
import { SideMenu } from '@components/ReferralBot/Menu/SideMenu'
import Typography from '@mui/material/Typography'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export function MainContainer() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const title = useAtomValue(headerTitleAtom)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const isFetching = useIsFetching()

  return (
    <Box
      sx={{ display: 'flex', width: '100%' }}
      className="ref-bot"
    >
      <CssBaseline />
      <Drawer
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <SideMenu otherMenuItemClick={handleDrawerClose} />
      </Drawer>
      <List sx={{ width: '100%', bgcolor: 'background.paper', pb: 9 }}>
        {Boolean(isFetching) && <LinearProgress sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(90deg, #e01cd5, #1CB5E0)'
          },
          backgroundColor: 'rgba(224, 28, 213, 0.1)'
        }} /> }
        <Typography
          variant="h6"
          sx={{
            p: 2,
            fontWeight: 500,
            color: 'text.primary',
            display: 'inline-block',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              left: '16px',
              bottom: '12px',
              height: '3px',
              width: '33%',
              background: 'linear-gradient(90deg, #e01cd5, #1CB5E0)',
              borderRadius: '2px'
            }
          }}
        >
          {title}
        </Typography>
        <Outlet />
      </List>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomMenu otherMenuItemClick={handleDrawerOpen} />
      </Paper>
    </Box>
  )
}
