import { Link } from '@tanstack/react-router'
import { t } from 'i18next'
import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import { homeScreenBotRoute } from '@components/ReferralBot/Home/router'
import ListItemIcon from '@mui/material/ListItemIcon'
import HomeIcon from '@mui/icons-material/Home'
import ListItemText from '@mui/material/ListItemText'
import { paymentBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Divider from '@mui/material/Divider'
import { BOT_GUIDE_LINK, SITE_LINK } from '@framework/constants'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LanguageIcon from '@mui/icons-material/Language'
import List from '@mui/material/List'
import { useQuery } from '@tanstack/react-query'
import { fetchUserProfile } from '@framework/api/referral-bot.api'
import { meCampaignsListBotRoute } from '@components/ReferralBot/Me/router'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

interface Props {
  otherMenuItemClick: () => void
}

export const SideMenu = ({otherMenuItemClick}: Props) => {
  const { data: userProfile, isLoading } = useQuery({
    queryKey: ['user_profile'],
    queryFn: () => fetchUserProfile(),
  })

  return (
    <>
      <List>
        <ListItem
          // @ts-ignore
          button
          component={Link}
          to={homeScreenBotRoute.id}
          onClick={otherMenuItemClick}
        >
          <ListItemIcon sx={{ minWidth: '35px' }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={t('referralBot.menu.home')} />
        </ListItem>
        <ListItem
          // @ts-ignore
          button
          component={Link}
          to={paymentBalanceBotRoute.id}
          onClick={otherMenuItemClick}
        >
          <ListItemIcon sx={{ minWidth: '35px' }}>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary={t('referralBot.menu.balance')} />
        </ListItem>
        <Divider />
        <ListItem
          // @ts-ignore
          button
          component={Link}
          to={BOT_GUIDE_LINK}
          target="_blank"
          onClick={otherMenuItemClick}
        >
          <ListItemIcon sx={{ minWidth: '35px' }}>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary={t('referralBot.sideMenu.support')} />
        </ListItem>
        <ListItem
          // @ts-ignore
          button
          component={Link}
          to={SITE_LINK}
          target="_blank"
          onClick={otherMenuItemClick}
        >
          <ListItemIcon sx={{ minWidth: '35px' }}>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={t('referralBot.sideMenu.site')} />
        </ListItem>
        {userProfile?.me && (
          <ListItem
            // @ts-ignore
            button
            component={Link}
            to={meCampaignsListBotRoute.id}
            onClick={otherMenuItemClick}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="All Campaigns" />
          </ListItem>
        )}
      </List>
    </>
  )
}
