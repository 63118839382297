import { Route } from '@tanstack/react-router'

import { referralBotRoute } from '../router'
import { PublicCampaignList } from '@components/ReferralBot/CampaignPublic/PublicCampaignList'

export const publicCampaignListBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/public-campaigns/list',
  component: PublicCampaignList,
})
