import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { Link, useMatch } from '@tanstack/react-router'
import { joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { t } from 'i18next'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { publicCampaignListBotRoute } from '@components/ReferralBot/CampaignPublic/router'
import CampaignIcon from '@mui/icons-material/Campaign'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu'
import * as React from 'react'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

interface Props {
  otherMenuItemClick: () => void
}

export const BottomMenu = ({otherMenuItemClick}: Props) => {
  const [value, setValue] = React.useState(0)
  const getCurrentValue = () => {
    const path = location.pathname
    if (path.includes('joined-campaigns')) return 0
    if (path.includes('public-campaigns')) return 1
    if (path.includes('own-campaigns')) return 2

    return 3
  }

  const btnSx = {
    '& .MuiBottomNavigationAction-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%'
    }
  }

  return (
    <>
      <BottomNavigation
        showLabels
        value={value || getCurrentValue()}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={Link}
          to={joinedCampaignListBotRoute.id}
          label={t('referralBot.menu.myStatus')}
          icon={<EmojiEventsIcon />}
          sx={btnSx}
        />
        <BottomNavigationAction
          component={Link}
          to={publicCampaignListBotRoute.id}
          label={t('referralBot.menu.publicCampaigns')}
          icon={<CampaignIcon />}
          sx={btnSx}
        />
        <BottomNavigationAction
          component={Link}
          to={ownCampaignListBotRoute.id}
          label={t('referralBot.menu.manageCampaigns')}
          icon={<DisplaySettingsIcon />}
          sx={btnSx}
        />
        <BottomNavigationAction label={t('referralBot.menu.fullMenu')} icon={<MenuIcon />} onClick={otherMenuItemClick} />
      </BottomNavigation>
    </>
  )
}
