export const API_PREFIX = import.meta.env.DEV ? 'http://localhost:4001' : 'https://api.inviteup.me'

export const BOT_NAME = 'InviteUpBot'

export const BOT_LINK = 'https://t.me/' + BOT_NAME

export const BOT_GUIDE_LINK = 'https://t.me/InviteUpGuideBot'
export const MANAGER_LINK = 'https://t.me/grulx';

export const SITE_LINK = 'https://inviteup.me'
