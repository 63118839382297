import React from 'react'
import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaignPosts } from '@framework/api/referral-bot.api'
import { PostStatus } from '@components/ReferralBot/OwnCampaign/Type/PostStatus'
import { t } from 'i18next'
import { PostItem } from '@components/ReferralBot/OwnCampaign/AiAgent/PostItem'

interface Props {
  campaignUuid: string
  postStatus: PostStatus
}

export const PostList = ({postStatus, campaignUuid}: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ['campaign_posts', campaignUuid, postStatus],
    queryFn: () => fetchCampaignPosts(campaignUuid, postStatus),
  })

  return (
    <>
      <Grid container spacing={2}>
        {data?.map((post) => (
          <Grid item xs={12} key={post.id}>
            <PostItem post={post} />
          </Grid>
        ))}
        {data?.length === 0 && (
          <Grid item xs={12}>
            {t('referralBot.nothingFound')}
          </Grid>
        )}
      </Grid>
    </>
  )
}
