import React, { useEffect } from 'react'
import { useParams } from '@tanstack/react-router'
import { ownCampaignAiAgentPostsBotRoute, ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { TopActions } from '@components/ReferralBot/TopActions'
import { Box, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { t } from 'i18next'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaign } from '@framework/api/referral-bot.api'
import { PostList } from '@components/ReferralBot/OwnCampaign/AiAgent/PostList'
import { PostStatus } from '@components/ReferralBot/OwnCampaign/Type/PostStatus'

export const Posts = () => {
  const {uuid} = useParams<typeof ownCampaignAiAgentPostsBotRoute>({
    from: ownCampaignAiAgentPostsBotRoute.id,
  })
  const [value, setValue] = React.useState('1');
  const [, setTitle] = useAtom(headerTitleAtom)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { data: campaign, } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  useEffect(() => {
    setTitle(t('referralBot.aiAgentPosts.title', {campaignName: campaign?.name || '...'}))
    return () => setTitle('')
  }, [campaign])

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.createCampaignSteps.backToCampaigns')}
        </Button>
      </TopActions>
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label={t('referralBot.aiAgentPosts.prepared')} value="1" />
              <Tab label={t('referralBot.aiAgentPosts.scheduled')} value="2" />
              <Tab label={t('referralBot.aiAgentPosts.published')} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PostList campaignUuid={uuid} postStatus={PostStatus.PREPARED} />
          </TabPanel>
          <TabPanel value="2">
            <PostList campaignUuid={uuid} postStatus={PostStatus.SCHEDULED} />
          </TabPanel>
          <TabPanel value="3">
            <PostList campaignUuid={uuid} postStatus={PostStatus.PUBLISHED} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}
