import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { t } from 'i18next'

import { FormHelperText, FormLabel, Grid, TextareaAutosize, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { validationSchema } from '@components/ReferralBot/OwnCampaign/Create/AiAgent/BaseInfoValidationSchema'
import { DateTimePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

export type AiAgentBaseInfo = z.infer<typeof validationSchema>

interface Props {
  onSave: (payload: AiAgentBaseInfo) => void
  loading: boolean
  data: AiAgentBaseInfo,
  disabled?: boolean
}

export function BaseInfoForm({ onSave, loading, data, disabled }: Props) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<AiAgentBaseInfo>({
    defaultValues: data,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      aria-disabled={disabled}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="name"
                label={t('referralBot.newOwnCampaignForm.name')}
                variant="outlined"
                error={!!errors.name}
                helperText={String(errors.name?.message || '')}
                disabled={loading || disabled}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormLabel>{t('referralBot.newOwnCampaignForm.contestDescription')}</FormLabel>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                id="description"
                minRows={5}
                style={{ width: '100%' }}
                disabled={loading || disabled}
              />
            )}
          />
          {errors.description?.message && <FormHelperText error>{errors.description.message}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="publishTime"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                label={t('referralBot.newOwnCampaignForm.publishTime')}
                format="HH:mm"
                ampm={false}
                views={['hours', 'minutes']}
                value={field.value ? DateTime.fromFormat(field.value, 'HH:mm') : null}
                onChange={(newValue) => {
                  field.onChange(newValue ? newValue.toFormat('HH:mm') : '');
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!errors.publishTime,
                    helperText: String(errors.publishTime?.message || '')
                  }
                }}
                disabled={loading || disabled}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            type="submit"
            disabled={disabled}
          >
            {t(`referralBot.save`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
