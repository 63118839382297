import React from 'react'
import { t } from 'i18next'
import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import { canStartCampaign, campaignHasNotEnoughBudget } from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { StopCampaign } from '@components/ReferralBot/OwnCampaign/Create/StopCampaign'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'

interface Props {
  campaign: CampaignDto
}

export const StartReferralCampaignStep = ({campaign}: Props) => {
  const isCanStartCampaign = campaign ? canStartCampaign(campaign) : false;
  const campaignStarted = campaign.status === CampaignStatus.ACTIVE
  const isBudgetNotEnough = campaign.status === CampaignStatus.INACTIVE && campaignHasNotEnoughBudget(campaign)

  return (
    <>
      <StatusTable
        campaign={campaign}
        pickFields={['budget', 'rewardPerUser', 'joinLink', 'status']}
      />
      {campaignStarted && (
        <Box
          mt={2}
          mb={2}
        >
          <h3>{t('referralBot.startCampaign.whatIsNextTitle')}</h3>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep1') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep2') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep3') || '' }} />
          </p>
        </Box>
      )}
      {isBudgetNotEnough && (
        <Alert sx={{mt: 2}} severity="warning">{t('referralBot.startCampaign.notEnoughBudget')}</Alert>
      )}
      {isCanStartCampaign && (
        <Alert severity="info">{t('referralBot.startCampaign.expalanation')}</Alert>
      )}
      <StopCampaign campaign={campaign} />
    </>
  )
}
