import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { finishCampaign } from '@framework/api/referral-bot.api'
import { getErrorMessage, isChannel, isContest } from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { Alert } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

interface Props {
  campaign: CampaignDto
}

export const StopCampaign = ({campaign}: Props) => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutate: finishCampaignMutate, isPending: isFinishingCampaign } = useMutation({
    mutationFn: finishCampaign,
  })

  const finishCampaignClick = () => {
    setConfirmDialog(false)

    finishCampaignMutate(campaign.uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.finishCampaign.campaignFinished'), { variant: 'success' })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns', campaign.uuid]
        })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  const canFinishCampaign = campaign?.status === CampaignStatus.ACTIVE

  return (
    <>
    {canFinishCampaign && (
      <Box
        mt={2}
        mb={2}
      >
        {isChannel(campaign) && (
          <Alert severity="warning">{t('referralBot.finishCampaign.explanation')}</Alert>
        )}

        <Box
          sx={{ p: 2 }}
          display="flex"
          justifyContent="center"
        >
          <LoadingButton
            loadingPosition="start"
            startIcon={<CancelIcon />}
            variant="contained"
            color="error"
            disabled={isFinishingCampaign}
            onClick={() => setConfirmDialog(true)}
          >
            {t('referralBot.finishCampaign.finishAction')}
          </LoadingButton>
        </Box>
        {confirmDialog && (
          <ConfirmDialog
            title={t('referralBot.finishCampaign.confirmFinish') || undefined}
            handleConfirm={() => finishCampaignClick()}
            handleCancel={() => setConfirmDialog(false)}
          />
        )}
      </Box>
    )}
    </>
  )
}
