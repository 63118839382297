import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { fetchPublicCampaigns } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { AlertMessage } from '@components/ReferralBot/Alert'
import { ListAsTable } from '@components/ReferralBot/CampaignPublic/ListAsTable'

export const PublicCampaignList = () => {
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.publicCampaigns.title') || '')

  const { data, isLoading } = useQuery({
    queryKey: ['campaigns_public'],
    queryFn: () => fetchPublicCampaigns(),
  })

  const campaigns = data || []

  return (
    <>
      {!isLoading && campaigns.length === 0 && <AlertMessage text={t('referralBot.publicCampaigns.noCampaigns')} />}
      {campaigns.length > 0 && <ListAsTable campaigns={campaigns} />}
    </>
  )
}
