import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { t } from 'i18next'

import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { validationSchema } from '@components/ReferralBot/OwnCampaign/Create/Contest/BaseInfoValidationSchema'
import { UploadImage } from '@components/ReferralBot/OwnCampaign/Create/Contest/UploadImage'

export type ContestBaseInfo = z.infer<typeof validationSchema>

interface Props {
  onSave: (payload: ContestBaseInfo) => void
  loading: boolean
  data: ContestBaseInfo,
  disabled?: boolean
}

export function BaseInfoForm({ onSave, loading, data, disabled }: Props) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ContestBaseInfo>({
    defaultValues: data,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  const onUploaded = (payload: { imgUrl: string }) => {
    setValue('imgUrl', payload.imgUrl)
  }

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      aria-disabled={disabled}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <UploadImage
            onUploaded={onUploaded}
            imgUrl={data.imgUrl}
            disabled={loading || disabled}
          />
          <Controller
            name="imgUrl"
            control={control}
            render={({ field }) => (
              <input
                type="hidden"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="name"
                label={t('referralBot.newOwnCampaignForm.name')}
                variant="outlined"
                error={!!errors.name}
                helperText={String(errors.name?.message || '')}
                disabled={loading || disabled}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormLabel>{t('referralBot.newOwnCampaignForm.contestDescription')}</FormLabel>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                id="description"
                minRows={5}
                style={{ width: '100%' }}
                disabled={loading || disabled}
              />
            )}
          />
          {errors.description?.message && <FormHelperText error>{errors.description.message}</FormHelperText>}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="joinButtonText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="joinButtonText"
                label={t('referralBot.newOwnCampaignForm.joinButtonText')}
                variant="outlined"
                error={!!errors.joinButtonText}
                helperText={String(errors.joinButtonText?.message || '')}
                disabled={loading || disabled}
              />
            )}
          />
          <FormHelperText>{t('referralBot.newOwnCampaignForm.joinButtonTextHelper')}</FormHelperText>
        </Grid>
        <Alert sx={{mt: 1, mb: 1}} severity="info">{t('referralBot.newOwnCampaignForm.publishExplanation')}</Alert>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="numberOfWinners"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="numberOfWinners"
                label={t('referralBot.newOwnCampaignForm.numberOfWinners')}
                type="number"
                variant="outlined"
                error={!!errors.numberOfWinners}
                helperText={String(errors.numberOfWinners?.message || '')}
                disabled={loading || disabled}
                onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="startAt"
            control={control}
            render={({ field }) => (
                <MobileDateTimePicker
                  {...field}
                  label={t('referralBot.newOwnCampaignForm.contestStartAt')}
                  disabled={loading || disabled}
                  timezone={'system'}
                />
            )}
          />
          {errors.startAt?.message && <FormHelperText error>{t(errors.startAt.message)}</FormHelperText>}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="finishAt"
            control={control}
            render={({ field }) => (
              <MobileDateTimePicker
                {...field}
                label={t('referralBot.newOwnCampaignForm.contestFinishAt')}
                disabled={loading || disabled}
                timezone={'system'}
              />
            )}
          />
          {errors.finishAt?.message && <FormHelperText error>{t(errors.finishAt.message)}</FormHelperText>}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="public"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={t('referralBot.newOwnCampaignForm.public')}
                disabled={loading || disabled}
                checked={field.value}
              />
            )}
          />
          <FormHelperText sx={{fontWeight: "bold"}}>{t('referralBot.newOwnCampaignForm.publicExplanation')}</FormHelperText>
          {errors.public?.message && <FormHelperText error>{t(errors.public.message)}</FormHelperText>}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            type="submit"
            disabled={disabled}
          >
            {t(`referralBot.save`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
