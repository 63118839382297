import React, { useState } from 'react'

import { Card, CardActions, CardMedia, FormHelperText } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'
import { uploadImage } from '@framework/api/referral-bot.api'
import { LoadingButton } from '@mui/lab'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

interface Props {
  onUploaded: (payload: { imgUrl: string }) => void
  imgUrl?: string
  disabled?: boolean
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function UploadImage({ onUploaded, imgUrl: existingImgUrl, disabled }: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [imgUrl, setImgUrl] = useState<string | null>(existingImgUrl || null)
  const { enqueueSnackbar } = useSnackbar()

  const onFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files ? event?.target?.files[0] : null

    if (file) {
      setLoading(true)

      try {
        const response = await uploadImage(file)

        setImgUrl(response.imgUrl)

        onUploaded({ imgUrl: response.imgUrl })
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      }

      setLoading(false)
    }
  }

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          disabled={disabled}
          loading={loading}
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{ width: '100%' }}
        >
          {t('referralBot.uploadImage.label')}
          <VisuallyHiddenInput
            type="file"
            onChange={onFileChanged}
            multiple={false}
          />
        </LoadingButton>
          <FormHelperText>{t('referralBot.uploadImage.restrictions')}</FormHelperText>
      </CardActions>
      {imgUrl && (
        <CardMedia
          sx={{ height: 345 }}
          image={imgUrl}
          style={{ opacity: loading ? 0.5 : 1 }}
          title="green iguana"
        />
      )}
    </Card>
  )
}
