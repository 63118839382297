import React from 'react'
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import { formatDateTime } from '@components/ReferralBot/helpers/date.helper'
import Button from '@mui/material/Button'
import { t } from 'i18next'
import { PostDto } from '@components/ReferralBot/OwnCampaign/Type/PostDto'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { schedulePost } from '@framework/api/referral-bot.api'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { useSnackbar } from 'notistack'
import { PostStatus } from '@components/ReferralBot/OwnCampaign/Type/PostStatus'

interface Props {
  post: PostDto
}

export const PostItem = ({post}: Props) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { mutate: schedulePostMutate, isPending } = useMutation({
    mutationFn: schedulePost,
  })

  const schedulePostClick = () => {
    setConfirmDialog(false)

    schedulePostMutate(post.id, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.success'), { variant: 'success' })

        await queryClient.invalidateQueries({
          queryKey: ['campaign_posts', post.campaignUuid, post.status]
        })
      },
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatDateTime(post.createdAt)}
            </Typography>
          </Box>
          <Typography variant="body1">{post.text}</Typography>
        </CardContent>
        {post.status === PostStatus.PREPARED && (
          <CardActions sx={{ justifyContent: 'flex-end', gap: 1 }}>
            <Button variant="outlined" color="primary" disabled>
              {t('referralBot.aiAgentPosts.regenerate')}
            </Button>
            <Button variant="contained" color="success" disabled={isPending} onClick={() => setConfirmDialog(true)}>
              {t('referralBot.aiAgentPosts.approve')}
            </Button>
          </CardActions>
        )}
      </Card>
      {confirmDialog && (
        <ConfirmDialog
          handleConfirm={() => schedulePostClick()}
          handleCancel={() => setConfirmDialog(false)}
        />
      )}
    </>
  )
}
