import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import React from 'react'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { StopCampaign } from '@components/ReferralBot/OwnCampaign/Create/StopCampaign'

interface Props {
  campaign: CampaignDto
}

export const StartAiAgentCampaignStep = ({campaign}: Props) => {
  return (
    <>
      <StatusTable campaign={campaign} />
      <StopCampaign campaign={campaign} />
    </>
  )
}
