import * as React from 'react'
import { t } from 'i18next'

import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import List from '@mui/material/List'
import { Button, Divider, ListItem, ListItemSecondaryAction } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import { SecondaryDetails } from '@components/ReferralBot/OwnCampaign/List/SecondaryDetails'
import { CampaignTitle } from '@components/ReferralBot/Campaign/CampaignTitle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface Props {
  campaigns: CampaignDto[]
}

export function ListAsTable({ campaigns }: Props) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {campaigns.map((item) => (
        <Box key={item.uuid}>
          <ListItem>
            <ListItemText
              primary={<CampaignTitle campaign={item} />}
            />
            <ListItemSecondaryAction>
              {item.joinLink && (
                <Button
                  component="a"
                  href={item.joinLink}
                  target="_blank"
                  rel="noreferrer"
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  {t('referralBot.publicCampaigns.open')}
                </Button>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider
            variant="middle"
            component="li"
            sx={{mt: 1, mb: 1}}
          />
        </Box>
      ))}
    </List>
  )
}
