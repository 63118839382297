import { get, patch, post } from '@framework/api/axios'
import { NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { CampaignStatusDto } from '@components/ReferralBot/Type/CampaignStatusDto'
import { ReferrerJoinedCampaign } from '@components/ReferralBot/Type/ReferrerJoinedCampaign'
import { CampaignBudget } from '@components/ReferralBot/Type/CampaignBudget'
import { UserProfile } from '@components/ReferralBot/Type/UserProfile'
import { PaymentTransaction } from '@components/ReferralBot/Type/PaymentTransaction'
import { ReferrerDetails } from '@components/ReferralBot/Type/ReferrerDetails'
import { CampaignMeDto } from '@components/ReferralBot/Type/CampaignMeDto'
import { UpdateCampaignBotType } from '@components/ReferralBot/OwnCampaign/SetBotUsername'
import { CampaignTypePayload } from '@components/ReferralBot/OwnCampaign/Create/CampaignTypeStep'
import { ContestBaseInfo } from '@components/ReferralBot/OwnCampaign/Create/Contest/BaseInfoForm'
import { ContestWinnerDTO } from '@components/ReferralBot/Type/ContestWinnerDTO'
import { toLuxonDateTime } from '@components/ReferralBot/helpers/date.helper'
import { AiAgentBaseInfo } from '@components/ReferralBot/OwnCampaign/Create/AiAgent/BaseInfoForm'
import { PostStatus } from '@components/ReferralBot/OwnCampaign/Type/PostStatus'
import { PostDto } from '@components/ReferralBot/OwnCampaign/Type/PostDto'
import { DateTime } from 'luxon'

// todo move it into env
// const domain = 'http://127.0.0.1:4001'
// const domain = 'https://9bbehx7hgf.execute-api.eu-central-1.amazonaws.com/prod'

// const patch = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.patch(`${domain}${url}`, data, {
//     ...config,
//     withCredentials: true,
//   })
//   return response.data
// }
//
// const post = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.post(`${domain}${url}`, data, {
//     ...config,
//     withCredentials: true,
//   })
//   return response.data
// }
//
// const get = async <T>(url: string, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.get(`${domain}${url}`, config)
//   return response.data
// }

const getTelegramHeaders = () => ({
  'bypass-tunnel-reminder': 'any',
  'skip_zrok_interstitial': 'true',
  // @ts-ignore
  'telegram-init-data': window?.Telegram?.WebApp?.initData,
})

export const createCampaign = async (payload: CampaignTypePayload): Promise<CampaignDto> => {
  return await post('/telegram-bot-mini-app/api/campaigns', payload, {
    headers: getTelegramHeaders(),
  })
}

type PatchCampaignType = NewCampaignType & {
  uuid: string
}

type PatchContestCampaignType = ContestBaseInfo & {
  uuid: string
}
type PatchAiAgentCampaignType = AiAgentBaseInfo & {
  uuid: string
}

type PatchCampaignBotType = UpdateCampaignBotType & {
  uuid: string
}

export const pathCampaignBot = ({ uuid, ...payload }: PatchCampaignBotType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}/bot`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const pathCampaign = ({ uuid, ...payload }: PatchCampaignType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const pathContestCampaign = ({ uuid, ...payload }: PatchContestCampaignType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}/contest`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const pathAiAgentCampaign = ({ uuid, ...payload }: PatchAiAgentCampaignType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}/ai-agent`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const fetchCampaigns = async (): Promise<CampaignDto[]> => {
  return get('/telegram-bot-mini-app/api/campaigns', {}, getTelegramHeaders())
}

export const fetchPublicCampaigns = async (): Promise<CampaignDto[]> => {
  return get('/telegram-bot-mini-app/api/campaigns-public', {}, getTelegramHeaders())
}

export const fetchCampaign = async (uuid: string): Promise<CampaignDto | null> => {
  const data = await get<object>(`/telegram-bot-mini-app/api/campaigns/${uuid}`, {}, getTelegramHeaders())

  return {
    ...data,
    // todo move these fields into the mapper
    createdAt: toLuxonDateTime('createdAt' in data ? String(data?.createdAt) : null),
    finishedAt: toLuxonDateTime('finishedAt' in data ? String(data?.finishedAt) : null),
    startedAt: toLuxonDateTime('startedAt' in data ? String(data?.startedAt) : null),
  } as CampaignDto;
}

export const startCampaign = async (uuid: string): Promise<CampaignDto> => {
  return patch(
    `/telegram-bot-mini-app/api/campaigns/${uuid}/start`,
    {},
    {
      // todo move it into one
      headers: getTelegramHeaders(),
    }
  )
}

export const cancelCampaign = async (uuid: string): Promise<void> => {
  await post(
    `/telegram-bot-mini-app/api/campaigns/${uuid}/cancel`,
    {},
    {
      headers: getTelegramHeaders(),
    }
  )
}

export const finishCampaign = async (uuid: string): Promise<CampaignDto> => {
  return patch(
    `/telegram-bot-mini-app/api/campaigns/${uuid}/finish`,
    {},
    {
      // todo move it into one
      headers: getTelegramHeaders(),
    }
  )
}

export const fetchCampaignConnectCode = async (uuid: string): Promise<{ connectCommand: string }> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/connect`, {},getTelegramHeaders())
}

export const fetchCampaignStatus = async (uuid: string): Promise<CampaignStatusDto> => {
  const data = await get<object>(`/telegram-bot-mini-app/api/campaigns/${uuid}/status`, {}, getTelegramHeaders())

  return {
    ...data,
    // todo move these fields into the mapper
    startedAt: toLuxonDateTime('startedAt' in data ? String(data?.startedAt) : null),
    finishedAt: toLuxonDateTime('finishedAt' in data ? String(data?.finishedAt) : null),
  } as CampaignStatusDto;
}

export const fetchReferrers = async (): Promise<ReferrerJoinedCampaign[]> => {
  return get('/telegram-bot-mini-app/api/referrers', {}, getTelegramHeaders())
}

export const fetchCampaignBudget = async (uuid: string): Promise<CampaignBudget> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/budget`, {}, getTelegramHeaders())
}

export const fetchCampaignWinners = async (uuid: string): Promise<ContestWinnerDTO[]> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/winners`, {}, getTelegramHeaders())
}

export const fetchUserProfile = async (): Promise<UserProfile> => {
  return get(`/telegram-bot-mini-app/api/users/me`, {}, getTelegramHeaders())
}

export const withdrawBalance = async (payload: { walletAddress: string }): Promise<void> => {
  await post('/telegram-bot-mini-app/api/users/withdraw-balance', payload, {
    headers: getTelegramHeaders(),
  })
}

export const fetchBalanceTransactions = async (): Promise<PaymentTransaction[]> => {
  return get('/telegram-bot-mini-app/api/users/balance-transactions', {}, getTelegramHeaders())
}

export const fetchReferrerDetails = async (uuid: string): Promise<ReferrerDetails> => {
  return get(`/telegram-bot-mini-app/api/referrers/${uuid}`, {}, getTelegramHeaders())
}

export const fetchCampaignsMe = async (): Promise<CampaignDto[]> => {
  return get('/telegram-bot-mini-app/api/me/campaigns', {}, getTelegramHeaders())
}

export const fetchCampaignMe = async (uuid: string): Promise<CampaignMeDto | null> => {
  return get(`/telegram-bot-mini-app/api/me/campaigns/${uuid}`, {}, getTelegramHeaders())
}

export const withdrawCampaignBudgetMe = async (uuid: string): Promise<void> => {
  return post(
      `/telegram-bot-mini-app/api/me/campaigns/${uuid}/withdraw`,
      {},
      {
        headers: getTelegramHeaders(),
      }
  )
}

export const uploadImage = async (file: File): Promise<{ imgUrl: string }> => {
  const formData = new FormData()
  formData.append('file', file)

  return post('/telegram-bot-mini-app/api/upload/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getTelegramHeaders(),
    },
  })
}

export const fetchCampaignPosts = async (uuid: string, status: PostStatus): Promise<PostDto[]> => {
  const data = await get<PostDto[]>(`/telegram-bot-mini-app/api/campaigns/${uuid}/posts?status=${status}`, {}, getTelegramHeaders())

  return data.map((item) => ({
    id: item.id,
    text: item.text,
    status: item.status,
    createdAt: toLuxonDateTime(String(item?.createdAt)) as DateTime,
    campaignUuid: uuid, // todo is there a better way to do this?
  }))
}

export const schedulePost = async (id: number): Promise<void> => {
  await patch(
    `/telegram-bot-mini-app/api/posts/${id}/schedule`,
    {},
    {
      headers: getTelegramHeaders(),
    }
  )
}
