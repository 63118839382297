import { Route } from '@tanstack/react-router'
import { List } from '@components/ReferralBot/OwnCampaign/List/List'
import { Add } from '@components/ReferralBot/OwnCampaign/Add'
import { Edit } from '@components/ReferralBot/OwnCampaign/Edit'
import { ConnectBot } from '@components/ReferralBot/OwnCampaign/ConnectBot'
import { Status } from '@components/ReferralBot/OwnCampaign/Status'
import { Start } from '@components/ReferralBot/OwnCampaign/Create/Start'
import { Fund } from '@components/ReferralBot/OwnCampaign/Fund'

import { referralBotRoute } from '../router'
import { SetBotUsername } from '@components/ReferralBot/OwnCampaign/SetBotUsername'
import { BaseInfoStep } from '@components/ReferralBot/OwnCampaign/Create/Contest/BaseInfoStep'
import { BaseInfoStep as AiAgentBaseInfoStep } from '@components/ReferralBot/OwnCampaign/Create/AiAgent/BaseInfoStep'
import { Posts } from '@components/ReferralBot/OwnCampaign/AiAgent/Posts'

export const ownCampaignListBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/list',
  component: List,
})

export const ownCampaignAddBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/add',
  component: Add,
})
export const ownCampaignEditBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/edit/$uuid',
  component: Edit,
})

export const ownCampaignEditContestBaseInfoBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/edit/$uuid/contest',
  component: BaseInfoStep,
})

export const ownCampaignConnectBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/connect/$uuid',
  component: ConnectBot,
})

export const ownCampaignSetBotUsernameRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/set-bot-username/$uuid',
  component: SetBotUsername,
})

export const ownCampaignStatusRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/status/$uuid',
  component: Status,
})

export const ownCampaignStartRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/start/$uuid',
  component: Start,
})

export const ownCampaignFundRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/fund/$uuid',
  component: Fund,
})

// ai agent

export const ownCampaignEditAiAgentBaseInfoBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/edit/$uuid/ai-agent',
  component: AiAgentBaseInfoStep,
})

export const ownCampaignAiAgentPostsBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/ai-agent/$uuid/posts',
  component: Posts,
})
